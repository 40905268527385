import { logEvent } from 'common/src/lib/analytics';
import _ from 'lodash';
import { ParsedUrlQuery } from 'querystring';

type Feature = 'Authentication' | 'ReferralList' | 'PatientReferral' | 'AccountDropdown';
type Component =
  | 'Login'
  | 'Logout'
  | 'Referral'
  | 'Save'
  | 'Artifact'
  | 'Help'
  | 'ContactUs'
  | 'ConfirmationDialogue'
  | 'VerifyMFACode'
  | 'BackToLogin'
  | 'MFALoginPage'
  | 'Export';
type Action = 'Success' | 'View' | 'Click' | 'Sort' | 'Filter';
export type PropertyKeys =
  | 'referral_id'
  | 'referral_status'
  | 'referral_status_effective_date'
  | 'referral_status_comment'
  | 'artifact_id'
  | 'document_version'
  | 'artifact_url'
  | 'button_name'
  | 'field'
  | 'sort_rule'
  | 'mfa_type'
  | 'referral_identifier'
  | 'referral_identifier_description';

export function logAnalyticsEvent({
  feature,
  component,
  action,
  properties,
}: {
  feature?: Feature;
  component?: Component;
  action?: Action;
  properties?: Partial<Record<PropertyKeys, unknown>>;
}) {
  const key = [feature, component, action].join('-');
  logEvent(key, properties);
}

const PATHNAME_TO_FEATURE_NAMES: Record<string, Feature> = {
  '/list-referrals': 'ReferralList',
  '/patient-referral': 'PatientReferral',
};

function queriesToProperties(queries: ParsedUrlQuery) {
  const properties: Record<string, unknown> = {};

  Object.keys(queries).forEach((query) => {
    properties[_.snakeCase(query)] = queries[query];
  });

  return properties;
}

export function pageViewEvent(pathname: string, query: ParsedUrlQuery) {
  const feature = PATHNAME_TO_FEATURE_NAMES[pathname];
  const properties = queriesToProperties(query);

  if (feature) {
    logAnalyticsEvent({
      feature: PATHNAME_TO_FEATURE_NAMES[pathname],
      action: 'View',
      properties: properties,
    });
  }
}

export function mfaPageViewEvent(properties: Partial<Record<PropertyKeys, unknown>>) {
  logAnalyticsEvent({
    feature: 'Authentication',
    action: 'View',
    component: 'MFALoginPage',
    properties: properties,
  });
}

export function authenticationEvent(action: Action, component?: Component) {
  logAnalyticsEvent({ feature: 'Authentication', action, component });
}
